import {
  // QUERY_URL_REST2,
  //QUERY_URL_BOSTAD,
  QUERY_URL_LITE,
  URL_IP,
  URL_IP_FUNCTION,
  STATS_SERVER,
} from "@/constants.js";

import axios from "axios";
import get from "lodash/get";
import {get_header} from '../components/f';

export default {
  
    

  async vitec_permissions(obj) {
    const headers = get_header();
    const funktion = "vitec/permissions";
    var org_key = get(obj, "org_key", "");
    var customerId = get(obj, "customerId", "");
    var userId = get(obj, "userId", " ");
    var phone_number = get(obj, "phone_number", "");
    phone_number = phone_number.replace("+", "");

    const url =
      URL_IP +
      funktion +
      "/" +
      org_key +
      "/" +
      customerId +
      "/" +
      userId +
      "/" +
      phone_number;
    // console.log(funktion,url);
    return await axios
      .get(url, {
        headers: headers,
      })
      .then((res) => {
        return get(res, "data.items", []);
      })
      .catch((e) => {
        return { status: 0, error: e.message };
      });
  },
  async vitec_customer_get(customerId) {
    const headers = get_header();
    const funktion = "vitec/customer";

    const url = URL_IP + funktion + "/" + customerId;
    // console.log(funktion,url);
    return await axios
      .get(url, {
        headers: headers,
      })
      .then((res) => {
        return get(res, "data", []);
      })
      .catch((e) => {
        return { status: 0, error: e.message };
      });
  },

  async vitec_batch_list(obj) {
    const headers = get_header();
    const funktion = "json/batch/vitec/list";
    var post_data = JSON.stringify(obj);
    const url = URL_IP + funktion;
   //  console.log(funktion, url, post_data);
    return await axios
      .post(url, post_data, {
        headers: headers,
      })
      .then((res) => {
     //  console.log(funktion, get(res, "data.A", []));
        return get(res, "data.A", []);
      })
      .catch((e) => {
        return { status: 0, error: e.message };
      });
  },

  async vitec_batch_obj(obj) {
    const headers = get_header();
    const funktion = "json/batch/vitec/participants"; //fd batch/vitec/obj
    var post_data = JSON.stringify(obj);

    const url = URL_IP + funktion;
   //console.log(url,post_data)
    return await axios
      .post(url, post_data, {
        headers: headers,
      })
      .then((res) => {
        return get(res, "data", {});
      })
      .catch((e) => {
        return { status: 0, error: e.message };
      });
  },
  async vitec_contact_obj(obj) {
    const headers = get_header();
    const funktion = "json/batch/vitec/contact";
    var post_data = JSON.stringify(obj);
    const url = URL_IP + funktion;
    // console.log(funktion,url,post_data)
    return await axios
      .post(url, post_data, {
        headers: headers,
      })
      .then((res) => {
        //console.log(funktion,url,JSON.stringify(res.data))
        return get(res, "data", []);
      })
      .catch((e) => {
        return { status: 0, error: e.message };
      });
  },
  async vitec_send_batch(obj) {
    const headers = get_header();
    const funktion = "json/batch/vitec/send";
    var post_data = JSON.stringify(obj);
    const url = URL_IP + funktion;
    // console.log(funktion,url,post_data)
    return await axios
      .post(url, post_data, {
        headers: headers,
      })
      .then((res) => {
        return get(res, "data", []);
      })
      .catch((e) => {
        return { status: 0, error: e.message };
      });
  },
  async vitec_contact_upd(obj) {
    const headers = get_header();
    const funktion = "vitec/contact/upd";
    var post_data = JSON.stringify(obj);
    const url = URL_IP + funktion;
    // console.log(funktion,url,post_data,obj)
    return await axios
      .post(url, post_data, {
        headers: headers,
      })
      .then((res) => {
        //console.log('rest2Api',funktion,res,res.data.A)
        return get(res, "data.A", "");
      })
      .catch((e) => {
        return { status: 0, error: e.message };
      });
  },
  async vitec_participant_obj(obj) {
    const headers = get_header();
    const funktion = "vitec/participant/upd";
    var post_data = JSON.stringify(obj);

    const url = URL_IP + funktion;

  //  console.log(funktion,url,post_data)

    return await axios
      .post(url, post_data, {
        headers: headers,
      })
      .then((res) => {
  //  console.log('rest2Api',funktion,res,res.data.A)
        return get(res, "data", []);
      })
      .catch((e) => {
        return { status: 0, error: e.message };
      });
  },

  async vitec_participant_obj_string(obj) {
    const headers = get_header();
    const funktion = "vitec/participant/upd";
   // var post_data = JSON.stringify(obj);

    const url = URL_IP + funktion;

   //console.log(funktion,url,obj)

    return await axios
      .post(url, obj, {
        headers: headers,
      })
      .then((res) => {
   //console.log('rest2Api',funktion,res,res.data.A)
        return get(res, "data", []);
      })
      .catch((e) => {
        return { status: 0, error: e.message };
      });
  },


  async vitec_participant_del(obj) {
    const headers = get_header();
    const funktion = "vitec/participant/del";
    var post_data = JSON.stringify(obj);
    const url = URL_IP + funktion;
    // console.log(funktion,url,post_data)
    return await axios
      .post(url, post_data, {
        headers: headers,
      })
      .then((res) => {
        return get(res, "data", []);
      })
      .catch((e) => {
        return { status: 0, error: e.message };
      });
  },

  async vitec_viewing_host(obj) {
    const headers = get_header();
    const funktion = "vitec/viewing_host";
    var post_data = JSON.stringify(obj);
    const url = URL_IP + funktion;
    // console.log(funktion,url,post_data)
    return await axios
      .post(url, post_data, {
        headers: headers,
      })
      .then((res) => {
        return get(res, "data", []);
      })
      .catch((e) => {
        return { status: 0, error: e.message };
      });
  },

  //----------------------------------------------
  // INFOPING
  //----------------------------------------------
  async verify_token(obj) {
    const headers = get_header();
    const funktion = "verify_token_v3";
    var post_data = JSON.stringify(obj);
    const url = URL_IP_FUNCTION + funktion;
    // console.log(funktion, url, post_data);
    return await axios
      .post(url, post_data, {
        headers: headers,
      })
      .then((res) => {
        // console.log(funktion, res.data.A);
        return get(res, "data.A", []);
      })
      .catch((e) => {
        return { status: 0, error: e.message };
      });
  },


  async org_branding_session(session) {
    const headers = get_header();
    const funktion = "org_branding_session";

    const url = URL_IP_FUNCTION + funktion;
    const obj = new Object();
    const Q = new Object();
    Q.session = session;
    obj.Q = Q;

    var post_data = JSON.stringify(obj);

    // console.log(funktion, url, post_data);
    return await axios
      .post(url, post_data, {
        headers: headers,
      })
      .then((res) => {
        return get(res, "data.A", []);
      })
      .catch((e) => {
        return { status: 0, error: e.message };
      });
  },
  async viewing_start_end_ins(obj) {
    const headers = get_header();
    const funktion = "viewing_start_end_ins";
    var post_data = JSON.stringify(obj);
    const url = URL_IP_FUNCTION + funktion;
    // console.log(funktion, url, post_data);
    return await axios
      .post(url, post_data, {
        headers: headers,
      })
      .then((res) => {
        return get(res, "data.A", []);
      })
      .catch((e) => {
        return { status: 0, error: e.message };
      });
  },

  async initiateLogin(phone_number) {
    const headers = get_header();
    const funktion = "login_initiate";
    const post_data = '{ "Q": { "phone_number": "' + phone_number + '"}}';
    const url = URL_IP_FUNCTION + funktion;
    // console.log(funktion, url, post_data);
    return await axios
      .post(url, post_data, {
        headers: headers,
      })
      .then((res) => {
        // console.log("svaret init login", res);
        return res.data.A;
      })
      .catch((e) => {
        // console.log("ERROR", e);
        return { status: "0", error: "Netowrk error " + e };
      });
  },

  async getCode(phone_number) {
    const headers = get_header();
    const funktion = "login_sms_send";
    const post_data = '{ "Q": { "phone_number": "' + phone_number + '"}}';
    const url = URL_IP_FUNCTION + funktion;

    // console.log(funktion, url, post_data);
    return await axios
      .post(url, post_data, {
        headers: headers,
      })
      .then((res) => {
        return res.data.A;
      })
      .catch((e) => {
        //console.log('ERROR', e);
        return { status: "0", error: "Netowrk error " + e };
      });
  },
  async confirmCode(sms_code, phone_number) {
    const headers = get_header();
    const funktion = "login_sms_confirm";
    const obj = new Object();
    const Q = new Object();
    Q.phone_number = phone_number;
    Q.code = sms_code;
    obj.Q = Q;

    var post_data = JSON.stringify(obj);

    // const post_data = `{
    //   "Q": {
    //     "phone_number": "${phone_number}",
    //     "code": "${sms_code}"
    //   }`;
    const url = URL_IP_FUNCTION + funktion;

    // console.log(funktion, url, post_data);

    return await axios
      .post(url, post_data, {
        headers: headers,
      })
      .then((res) => {
        // console.log(funktion, res);
        return res.data.A;
      })
      .catch((e) => {
        //console.log('ERROR', e);
        return { status: "0", error: "Netowrk error " + e };
      });
  },

  // async batch_load(obj) {
  //   const headers = get_header();
  //   const funktion = "batch_load";
  //   var post_data = JSON.stringify(obj);
  //   const url = URL_IP_FUNCTION + funktion;
  //   // console.log(funktion, url, post_data);
  //   return await axios
  //     .post(url, post_data, {
  //       headers: headers,
  //     })
  //     .then((res) => {
  //       // console.log(funktion, res);
  //       return get(res, "data.A", []);
  //     })
  //     .catch((e) => {
  //       return { status: 0, error: e.message };
  //     });
  // },

  async postStatsServer(funktion, obj) {
    const headers = get_header();
    headers.session = process.env.VUE_APP_VIEWING_SESSION;
    headers.Accept = 'application/json';
    var post_data = JSON.stringify(obj);
    const url = STATS_SERVER + funktion;
   //console.log('===>>>  To statsserver', headers, funktion, url, post_data);
    return await axios
      .post(url, post_data, {
        headers: headers,
      })
      .then((res) => {
         //console.log('<<== from statsserver', funktion, res);
        return res;
      })
      .catch((e) => {
        return { status: 0, error: e.message };
      });
  },

  async check_access(obj) {
    const headers = get_header();
    const funktion = "access";

    const url = URL_IP + funktion;
    var post_data = JSON.stringify(obj);
    // console.log(funktion, url, post_data);
    return await axios
      .post(url, post_data, {
        headers: headers,
      })
      .then((res) => {
        //console.log(' svar check_access', res);
        return get(res, "data", []);
      })
      .catch((e) => {
        return { status: 0, error: e.message };
      });
  },

  async lang_filter(obj) {
    const headers = get_header();
    const funktion = "lang_filter";
    var lang = "sv";
    var filter = "all";

    if (obj.lang) {
      if (obj.lang) {
        if (obj.lang.trim() != "") lang = obj.lang;
      }
      if (obj.filter) {
        if (obj.filter.trim() != "") filter = obj.filter;
      }
      const url = QUERY_URL_LITE + funktion + "/" + lang + "/" + filter;

      // console.log(funktion, url,obj);
      return await axios
        .get(url, {
          headers: headers,
        })
        .then((res) => {
          var ans = res.data;

          // console.log(funktion, "ans", ans);
          return ans;
        })
        .catch((e) => {
          alert("ERROR:" + e);
          return null;
        });
    } else return null;
  },

  async lang_chk(lang) {
    const headers = get_header();
    const funktion = "lang_chk";

    const url = QUERY_URL_LITE + funktion + "/" + lang;

    // console.log(funktion, url);
    return await axios
      .get(url, {
        headers: headers,
      })
      .then((res) => {
        var ans = res.data;
        // console.log('ans lang', ans);
        return ans;
      })
      .catch((e) => {
        alert("ERROR:" + e);
        return null;
      });
  },

};
