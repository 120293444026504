export default {
//----------------------------------------------
//  CONSTANTS
//----------------------------------------------
axios_headers(){
  return "{ headers: {'Content-Type': 'application/json','Access-Control-Allow-Origin': '*',}}"
},
//----------------------------------------------
//  UTIL
//----------------------------------------------
clean_null_obj(obj){
  return JSON.parse(JSON.stringify(obj,(key, value) => {
    if (value !== null) return value
  }))
},
//----------------------------------------------
// VITEC
//----------------------------------------------
vitec_permissions_obj() {
  const obj = new Object();
  obj.org_key = null;
  obj.customerId = null;
  obj.userId = null;
  obj.phone_number= null;
  
  return obj;
},
  vitec_batch_list() {
    const obj = new Object();
    const Q = new Object();
    Q.session = null;
    Q.pageNum = '1';
    Q.rowsPerPage = '1000';  
    Q.searchStr = '<<all>>';
    obj.Q = Q;
    return obj;
  },
  vitec_batch_obj() {
    const obj = new Object();
    const Q = new Object();
    Q.session = null;
    Q.batch_key = null;
    Q.pageNum = '1';
    Q.rowsPerPage = '100000';
    obj.Q = Q;
    return obj;
  },
  vitec_batch_send_obj() {
    const obj = new Object();
    const Q = new Object();
    Q.session = null;
    Q.batch_key = null;
    obj.Q = Q;
    return obj;
  },
  vitec_attended_obj() {
    const obj = new Object();
    const Q = new Object();
    Q.session = null;
    Q.batch_key = null;
    Q.firstName = null;
    Q.lastName = null;
    Q.cellPhone = null;
    Q.emailAddress = null;
    Q.valuation = null;
    Q.bank = null;
    Q.contactId = null;
    Q.notes = null;
    obj.Q = Q;
    return obj;
  },
  vitec_contact_get_obj() {
    const obj = new Object();
    const Q = new Object();
    Q.customerId = null;
    Q.cellPhone = null;
    Q.emailAddress = null;
    obj.Q = Q;
    return obj;
  },
  
  vitec_contact_upd_obj() {
    const obj = new Object();
    const Q = new Object();
    Q.customerId = null;
    Q.contactId = null;
    Q.cellPhone = null;
    Q.emailAddress = null;
    Q.firstName = null;
    Q.lastName = null;
    obj.Q = Q;
    return obj;
  },
  vitec_participant_obj() {
    const obj = new Object();
    const Q = new Object();
    Q.session = null;
    Q.batch_key = null;
    Q.batch_gen_items_key = null;
    Q.customerId = null;
    Q.viewingId = null;
    Q.firstName = null;
    Q.lastName = null;
    Q.cellPhone = null;
    Q.emailAddress = null;
    Q.valuation = null;
    Q.wish_lone_promise = null;
    Q.attended = null;
    Q.streetAddress = null;
    Q.contactId = null;
    Q.has_lone_promise = null;
    Q.note = null;
    obj.Q = Q;
    return obj;
  },
  
  vitec_viewing_host_obj() {
    const obj = new Object();
    const Q = new Object();
    Q.batch_key = null;
    Q.phone_number = null;
    Q.user_name = null;
    Q.customerId = null;
    Q.access = null;
    Q.what = null;
    obj.Q = Q;
    return obj;
  },
//----------------------------------------------
// INFOPING
//----------------------------------------------

ip_verify_token_obj() {
  const obj = new Object();
  const Q = new Object();
  Q.session = null;
  Q.token = null;
  Q.login = null;
  obj.Q = Q;
  return obj;
},

ip_viewing_start_end_ins() {
  const obj = new Object();
  const Q = new Object();
  Q.batch_key = null;
  Q.batch_name = null;
  Q.what = null;
  obj.Q = Q;
  return obj;
},

// batch_load() {
//   const obj = new Object();
//   const Q = new Object();
//   Q.session = null;
//   Q.batch_key = null;
//   Q.use_api = '1';
//   obj.Q = Q;
//   return obj;
// },
init_report_obj() {
  const obj = new Object();
 
  obj.session = null;
  obj.org_key = null;
  obj.superadmin = null;
  obj.date_from= null;
  obj.date_to = null;
 
  return obj;
},
};
