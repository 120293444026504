<template>
  <div>
    <v-container fluid style="padding: 0px">
      <v-row no-gutters>
        <v-col cols="1"> </v-col>
        <v-col cols="9">
          <v-row no-gutters> {{ is_superagent() }} </v-row>

          <v-row justify="center" no-gutters>
            <div>
              <img class="logo" />
            </div>
          </v-row>

          <v-row justify="center" no-gutters>
            <!-- SELECTED VIEWING  class="left"-->

            <div
              style="font-size: 0.8em; margin-bottom: 15px"
              @click="click_viewing()"
            >
              {{ get_selected_viewing() }}
            </div>
          </v-row>
        </v-col>
        <v-col cols="2" class="right">
          <!-- MENU BUTTON -->
          <div v-if="loggedin" :class="get_class_loggedin">
            <v-app-bar-nav-icon @click="drawerOpen"> </v-app-bar-nav-icon>
          </div>
        </v-col>
      </v-row>
    </v-container>

    <!-- Menyn och innehåll   -->
    <div>
      <v-navigation-drawer v-model="drawer" nav app right>
        <v-list height="100%" nav dense>
          <v-list-item @click="drawerClose">
            <v-list-item-icon>
              <v-icon>mdi-close</v-icon>
            </v-list-item-icon>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-label>{{
                this.getLang('v6_viewing', 'lbl_loggedin').toUpperCase()
              }}</v-label>
              <br />

              <v-list-item-subtitle>
                {{ logged_in_as() }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <!-- Visningar -->
          <v-divider v-if="visningar"></v-divider>
          <v-list-item v-if="visningar" @click="click_visningar()">
            <v-list-item-icon>
              <v-icon>roofing</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{
              this.getLang('v6_viewing', 'lbl_viewings').toUpperCase()
            }}</v-list-item-title>
          </v-list-item>

          <!-- REPORTS - SURVEYS  -->
          <v-divider v-if="reports"></v-divider>
          <v-list-item v-if="reports" @click="click_reports()">
            <v-list-item-icon>
              <v-icon>insert_chart_outlined</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{
              this.getLang('v6_viewing', 'lbl_reports').toUpperCase()
            }}</v-list-item-title>
          </v-list-item>

          <!-- VIEWING HOST -->
          <v-divider v-if="access_viewing_host"></v-divider>
          <v-list-item v-if="access_viewing_host" @click="click_viewing_host()">
            <v-list-item-icon>
              <v-icon>mdi-format-list-checks</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{
              this.getLang('v6_viewing', 'lbl_viewinghost').toUpperCase()
            }}</v-list-item-title>
          </v-list-item>

          <!-- Vitec  -->
          <v-divider></v-divider>
          <v-list-item v-if="vitec" @click="click_vitec()">
            <v-list-item-icon>
              <v-icon>insert_chart_outlined</v-icon>
            </v-list-item-icon>
            <v-list-item-title>VITEC</v-list-item-title>
          </v-list-item>

          <!-- WIDERLÖV INTAGSMODUL
          <v-divider v-if="intake"></v-divider>
          <v-list-item v-if="intake" @click="click_intake()">
            <v-list-item-icon>
              <v-icon>home</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{
              this.getLang('v6_viewing', 'lbl_intake').toUpperCase()
            }}</v-list-item-title>
          </v-list-item> -->

          <!-- BROKER LOGIN -->
          <v-divider v-if="brokerLogin"></v-divider>
          <v-list-item v-if="brokerLogin" @click="click_brokerLogin()">
            <v-list-item-icon>
              <v-icon>login</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{
              this.getLang('v6_viewing', 'lbl_colleague').toUpperCase()
            }}</v-list-item-title>
          </v-list-item>

          <!-- LOG OUT  -->
          <v-divider></v-divider>
          <v-list-item @click="click_logout()">
            <v-list-item-icon>
              <v-icon>logout</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{
              this.getLang('v6_viewing', 'lbl_logout').toUpperCase()
            }}</v-list-item-title>
          </v-list-item>

          <!-- VERSION -->
          <v-divider></v-divider>
          <v-list-item>
            <v-list-item-icon>
              <v-icon>mdi-numeric</v-icon>
            </v-list-item-icon>
            <v-list-item-title>v 3.8</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>
      <div>
        <!-- SEND NOW -->
        <v-dialog
          content-class="my-send-dialog"
          v-model="send_dialog"
          overlay-color="transparent"
        >
          <v-card style="padding: 10px">
            <v-col cols="12">
              <v-row>
                {{ this.getLang('v6_viewing', 'lbl_sendmsg') }}
              </v-row>
              <br />
              <v-divider></v-divider>
              <br />
              <v-row>
                <v-btn @click="click_send_cancel()">
                  {{ this.getLang('v6_viewing', 'lbl_cancel') }}</v-btn
                >
                <v-spacer></v-spacer>
                <v-btn @click="click_send_exec()">
                  {{ this.getLang('v6_viewing', 'lbl_send') }}</v-btn
                >
              </v-row>
            </v-col>
          </v-card>
        </v-dialog>
        <!-- VIEWING HOST -->
        <viewingAssistant
          v-if="show_viewing_host"
          @closeMe="
            () => {
              closeViewingHost();
            }
          "
        />
      </div>
    </div>
  </div>
</template>

<script>
import get from 'lodash/get';

import rest2Api from '@/api/rest2Api.js';
import viewingAssistant from './viewingAssistant.vue';
import componentsMixin from './componentsMixin.js';
import Q from '@/questions.js';
import { COOKIE_NAME_SESSION } from '@/constants.js';

export default {
  name: 'Header',
  mixins: [componentsMixin],
  props: {
    msg: String,
  },
  data() {
    return {
      loggedin: false,
      send_dialog: false,
      drawer: false,

      reports: false,
      //intake: false,

      brokerLogin: false,

      visningar: false,
      selected_viewing: false,
      show_viewing_host: false,
      access_viewing_host: false,

      search: '',
      obj: Q.init_report_obj(),
    };
  },

  watch: {},

  computed: {
    vitec() {
      var superA = get(this.$store.state.infopingstore, 'user_type', '');
      if (superA == 'superagent') return true;
      else return false;
    },

    get_class_name() {
      var result = 'font-name-' + this.$vuetify.breakpoint.name;
      return result;
    },
    get_class_loggedin() {
      var result = 'font-name-' + this.$vuetify.breakpoint.name;

      return result;
    },
  },
  beforeMount() {
    this.check_access();
    //this.set_branding();
    this.is_viewings();
  },
  mounted() {},
  created() {
    this.loggedin = true;
    //console.log('created','Header')
  },
  methods: {
    check_access() {
      // var session = get(this.$store.state.infopingstore, 'session', null);
      // this.$store.dispatch('infopingstore/check_access', session).then(() => {
      var access = get(this.$store.state.infopingstore, 'access', null);
      // console.log('access', access);
      if (access.length > 0) {
        access.forEach((element) => {
          //  if (element.name == 'intake')
          // lägg till om superagent ska ha intag  || element.name == 'superagent'
          //   this.intake = true;
          if (element.name.includes('report')) this.reports = true;
          if (element.name == 'share_brokers') this.brokerLogin = true;
          if (element.name == 'viewing_assistant')
            this.access_viewing_host = true;
          if (element.name == 'superagent' || element.name == 'superadmin') {
            this.brokerLogin = true;
            this.reports = true;
          }
        });
      }
      //   });
    },

    is_viewings() {
      this.visningar = true;
    },

    is_superagent() {
      var answer = '';

      var header_name = get(
        this.$store.state.infopingstore,
        'header_name',
        null
      );
      if (header_name != null) answer = header_name;
      return answer;
    },

    drawerOpen() {
      //console.log(window.location.origin)
      this.drawer = true;
    },
    drawerClose() {
      this.drawer = false;
    },
    click_viewing() {
      if (this.selected_viewing && this.selected_viewing != null)
        this.send_dialog = true;
    },
    click_viewing_host() {
      this.show_viewing_host = true;
      //console.log('viewing host', this.show_viewing_host);
      this.drawerClose();
    },
    click_logout() {
      this.$store.dispatch('infopingstore/clear_all');

      if (this.$cookies.isKey(COOKIE_NAME_SESSION))
        this.$cookies.remove(COOKIE_NAME_SESSION);

      window.location.href = window.location.origin;
    },

    click_brokerLogin() {
      // töm selected viewing
      this.$store.dispatch('infopingstore/fetch_selected_viewing');
      this.$router.push({
        path: '/brokerlogin',
      });
    },
    click_reports() {
      // töm selected viewing
      this.$store.dispatch('infopingstore/fetch_selected_viewing');
      this.$router.push({
        path: '/reports',
      });
    },
    // click_intake() {
    //   var session = get(this.$store.state.infopingstore, 'session', null);
    //   var org_key = get(
    //     this.$store.state.infopingstore,
    //     'org_branding.org_key',
    //     null
    //   );
    //   window.open(
    //     'https://infoping.net/sv/admin/visning/?s=' +
    //       session +
    //       '&org_key=' +
    //       org_key
    //   );
    // },
    click_vitec() {
      // töm selected viewing
      this.$store.dispatch('infopingstore/fetch_selected_viewing');
      this.$router.push({
        path: '/vitec',
      });
    },
    click_visningar() {
      this.$router.push({
        path: '/',
      });
    },
    click_send_cancel() {
      this.send_dialog = false;
    },
    click_send_exec() {
      this.send_dialog = false;
      var obj = Q.vitec_batch_send_obj();

      obj.Q.session = get(this.$store.state.infopingstore, 'session', null);

      obj.Q.batch_key = get(
        this.$store.state.infopingstore,
        'selected_viewing.item.batch_key',
        null
      );

      obj = Q.clean_null_obj(obj);

      // SEND BATCH

      rest2Api.vitec_send_batch(obj);
      //sara testar
      this.$store.dispatch('infopingstore/fetch_viewing_participants', obj);
    },
    get_selected_viewing() {
      var result = '';
      var batch_name = get(
        this.$store.state.infopingstore.selected_viewing,
        'item.batch_name',
        null
      );

      if (batch_name != null) {
        result = batch_name;
        this.selected_viewing = true;
      } else this.selected_viewing = false;

      return result;
    },
    logged_in_as() {
      var result = '';
      var phone_number = get(
        this.$store.state.infopingstore,
        'verify_token.phone_number',
        null
      );

      if (phone_number != null) {
        result = phone_number;
      }
      return result;
    },

    closeViewingHost() {
      this.show_viewing_host = false;
    },
  },
  components: { viewingAssistant },
};
</script>
<style scoped></style>
