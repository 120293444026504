<template>
  <div :class="{ desktopView: $vuetify.breakpoint.mdAndUp }">
    <!--:elevation="$vuetify.breakpoint.mdAndUp ? '1' : '0'"-->
    <v-card
      flat
      :style="
        $vuetify.breakpoint.xs
          ? 'margin: var(--page-margins-mobile); padding-top: 10px; margin-top: -3px;'
          : 'padding-top: 10px; margin-top: -3px;'
      "
    >
      <center>
        <label style="font-size: 0.8em">{{
          this.getLang('v6_viewing', 'txt_answers')
        }}</label>
      </center>
      <br />
      <v-list
        subheader
        two-line
        style="width: 100%; font-size: 0.8em; height: 100vh;"
      >
        <v-row no-gutters style="padding-left: 35px">
          <v-col dense cols="6" xl="4" lg="4" md="4" sm="4">
            <span>
              {{ this.getLang('v6_viewing', 'lbl_name').toUpperCase() }}</span
            >
          </v-col>

          <v-col v-if="$vuetify.breakpoint.smAndUp" dense cols="2"
            ><span>
              {{ this.getLang('v6_viewing', 'lbl_phone').toUpperCase() }}</span
            ></v-col
          >

          <v-col dense cols="6" xl="6" lg="6" md="6" sm="6">
            <span>
              {{ this.getLang('v6_viewing', 'lbl_status').toUpperCase() }}</span
            >
          </v-col>
        </v-row>
        <v-row no-gutters dense>
          <v-list-item-group style="width: 100%">
            <template v-for="item in selected_batch">
              <v-list-item class="borderContainer" :key="item.Hex">
                <template>
                  <!--  <v-row no-gutters  dense @click="seeComment(item)">-->
                  <v-col dense cols="6" xl="4" lg="4" md="4" sm="4">
                    <v-list-item-content>
                      <v-list-item-subtitle
                        style=" font-size: 1.2em"
                        v-text="item.ContactName"
                      ></v-list-item-subtitle>

                      <a
                        v-if="$vuetify.breakpoint.xsOnly"
                        :href="'tel:' + item.ContactPhoneNumber"
                        >{{ item.ContactPhoneNumber }}</a
                      >
                    </v-list-item-content>
                  </v-col>

                  <v-col dense v-if="$vuetify.breakpoint.smAndUp" cols="2">
                    <v-list-item-content>
                      <a :href="'tel:' + item.ContactPhoneNumber">{{
                        item.ContactPhoneNumber
                      }}</a>
                      <!-- <v-list-item-subtitle 
                        style=" font-size: 1.2em"
                        v-text="item.c3"
                      >
                      </v-list-item-subtitle>-->
                    </v-list-item-content></v-col
                  >

                  <v-col
                    @click="seeComment(item)"
                    dense
                    cols="6"
                    xl="4"
                    lg="4"
                    md="4"
                    sm="4"
                  >
                    <v-list-item-content>
                      <v-list-item-subtitle
                        style=" font-size: 1.2em"
                        v-text="item.translatedStatus"
                      ></v-list-item-subtitle>
                      <v-list-item-subtitle
                        style=" font-size: 1.2em"
                        v-text="item.Comments"
                      ></v-list-item-subtitle>
                    </v-list-item-content>
                  </v-col>
                  <!--  </v-row>-->
                </template>
              </v-list-item>
            </template>
          </v-list-item-group>
        </v-row>
      </v-list>

      <template>
        <v-footer
          fixed
          :class="{ desktopView: $vuetify.breakpoint.mdAndUp }"
          style="border-top: 1px solid #d2d2d2"
        >
          <v-col style="padding-top: 0px" class="text-center" cols="12">
            <v-text-field
              background-color="var(--search-field-bg-color)"
              solo
              dense
              style="margin-bottom: 5px; padding-top: 5px"
              flat
              v-model="searchValue"
              append-icon="mdi-magnify"
              :label="this.getLang('v6_viewing', 'lbl_searchparticipant')"
              single-line
              hide-details
              clearable
              @click:clear="clearSearch()"
            >
            </v-text-field>
          </v-col>
        </v-footer>
      </template>
    </v-card>
  </div>
</template>

<script>
import get from 'lodash/get';
import componentsMixin from './componentsMixin.js';
//import rest2Api from '@/api/rest2Api.js';
export default {
  name: 'Answers',
  mixins: [componentsMixin],
  data() {
    return {
      searchValue: '',
    };
  },
  components: {},
  watch: {},

  destroyed() {
    this.$store.dispatch('infopingstore/clear_batch');
  },
  beforeMount() {
    if (this.batch_key) this.get_batch();
  },
  async mounted() {
    //gick inte att ha detta i selected viewing pga timingproblem.
    //funkar inte att watcha storen, kollar här om det är en reload (skulle helst göra det när visningarna finns i storen)

    if (!this.batch_key) {
      var res = await this.setDefaultViewing(this.$route.params.id);
      if (res == 'found viewing') this.get_batch();
      else {
        this.alertDialogNoCancel(
          this.getLang('v6_viewing', 'lbl_notfound'),
          this.getLang('v6_viewing', 'txt_notfound'),
          () => console.log('user ok')
        );
      }
    }
  },

  computed: {
    batch_key() {
      return get(
        this.$store.state.infopingstore,
        'selected_viewing.item.batch_key',
        null
      );
    },
    estateId() {
      return get(
        this.$store.state.infopingstore,
        'selected_viewing.item.estateId',
        null
      );
    },
    startsAt() {
      return get(
        this.$store.state.infopingstore,
        'selected_viewing.item.startsAt',
        null
      );
    },
    selected_batch() {
      var translations = {
        DELIVERED: 'Levererat',
        EXPIRED: 'Misslyckad leverans',
        PENDING: 'Pågående',
        REJECTED: 'Avvisat',
        UNDELIVERABLE: 'Olevererbart',
        OPEN: 'Läst',
      };

      var obj = get(this.$store.state.infopingstore, 'batch', []);

      if (obj) {
        var filtered = obj;

        if (this.searchValue) {
          filtered = obj.filter((item) => {
            return item.ContactName.toLowerCase().match(
              this.searchValue.toLowerCase()
            );
          });
        }

        return filtered.map((item) => ({
          ...item,
          translatedStatus: translations[item.Status] || item.Status,
        }));
      } //obj.item
      else return [];
    },
  },
  methods: {
    clearSearch() {
      this.searchValue = '';
    },

    get_batch() {
      var obj = {};
      obj.EstateId = this.estateId;
      obj.StartsAt = this.startsAt;

      this.$store.dispatch('infopingstore/fetchAnswers', obj);
    },

    seeComment(item) {
      if (item && item.Comments != '') {
        this.alertDialogNoCancel(
          'Kommentar',
          item.Comments,

          () => console.log('user ok')
        );
      }
    },

    alertDialogNoCancel(headerText, bodyText, okFunc) {
      this.$store.dispatch('infopingstore/showAlert', {
        show: true,
        headerText: headerText,
        bodyText: bodyText,
        showCancelButton: false,
        confirmAction: () => {
          okFunc();
        },
      });
    },
  },
};
</script>

<style scoped>
.fixed {
  bottom: 0;
  height: 150px;

  position: fixed;
  width: 100%;
}
/*
.largeScreen {
  height: 100vh;


  border: 1px solid;
  border-color: rgb(169, 168, 168);
  border-style: ridge;
}*/
</style>
